export const taxonomyTeamData = {
    init() {
        // JavaScript to be fired on all pages
        $(document).click(function () {
            closePopups();
        });

        $('.js-team-map-image').on('scroll', function () {
            closePopups();
        });

        $(window).on('load', function () {
            $('[id^="area-id-"]').on('click', function (e) {
                const $el = $(this)[0].getBBox();
                const elWidth = $el.width;
                const elHeight = $el.height;
                const bounding = $(this)[0].getBoundingClientRect();
                const position = { left: bounding.left, top: bounding.top };
                let id = $(this).attr('id');

                id = id.replace('area-id-','');

                // Reset active state of all elements
                closePopups();

                $(this).addClass('is-active');

                showPopup(id, position.left, position.top, elWidth, elHeight);
                e.stopPropagation();
            });

            $('.js-team-map-button').on('click', function (e) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: $('.js-team-map-members').offset().top - 120, // 120 = .header height of 80px + 40px whitespace
                }, 600);
            });
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

function showPopup(id, posX, posY, elWidth, elHeight)
{
    const $map = $('.team-map__container');
    const mapPosY = $map.offset().top;
    const mapPosX = $map.offset().left;
    const scrollPos = $(window).scrollTop()
    let hasTeamLinked = false;

    $('.js-team-map-popup').each(function () {
        const idArray = $(this).data('ids');

        if (jQuery.inArray(id, idArray) !== -1) {
            $(this).addClass('is-active').css({
                'left': (posX - mapPosX) + (elWidth / 2),
                'top': (posY - mapPosY) + (elHeight / 2) + scrollPos,
            });
            hasTeamLinked = true;
        }
    });

    if (!hasTeamLinked) {
        $('.js-team-map-popup-error').addClass('is-active');
    }
}

function closePopups()
{
    $('.js-team-map-popup, .js-team-map-popup-error').each(function () {
        $('[id^="area-id-"]').removeClass('is-active');
        $(this).removeClass('is-active').removeAttr('style');
    });
}

